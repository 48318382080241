import React from "react";
import { Helmet } from "react-helmet";

const errorGse = () => {
  return (
    <div>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <img
            class="mx-auto w-1/3"
            src="https://imgix.cosmicjs.com/fc7453b0-b6fb-11ea-b20f-f350ffc5594c-ConsultFGCLogosq.jpg"
            alt="logo"
          />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="max-w-2xl mx-auto text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-red-700 sm:text-5xl sm:leading-none md:text-6xl">
            There Was An Error In Your Submission....
          </h2>

          <div class="rounded-md">
            <div class="mt-10 rounded-md sm:flex-shrink-0">
              <a
                href="/"
                class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-secondaryBlue to-primaryBlue hover:opacity-70"
              >
                Return Home
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default errorGse;
